.reviews {
  padding-top: 100px;
  padding-bottom: 70px;
  transition: .3s linear;
  @include sm-block {
    padding-top: 80px; }
  @include xxs-block {
    padding-top: 50px;
    padding-bottom: 40px; } }
.reviews__wrap {
  display: flex;
  padding: 30px 20px;
  border: 5px solid #e9e9e9;
  @include sm-block {
    flex-direction: column; } }
.reviews__img, .reviews__desc {
	@include col();
	flex: 1; }
.reviews__img {
  text-align: center;
  @include sm-block {
    margin-bottom: 20px; } }
.reviews__img-pic {
  @include sm-block {
    width: 100%; } }
.reviews__desc {
	display: flex;
	flex-direction: column; }
.reviews__text {
  margin-bottom: 20px;
  @include xxs-block {
    font-size: 14px; } }
.reviews__user-name {
  font-size: 18px;
  @include font-face(bold);
  @include xxs-block {
    font-size: 16px; } }
.services-slider_prev {
  border-radius: 50%;
  &::before {
        content: "\f053";
        font-family: 'FontAwesome';
        display: block;
        font-size: 26px;
        color:  $red;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%); } }
.services-slider_next {
  border-radius: 50%;
  &::before {
        content: "\f054";
        font-family: 'FontAwesome';
        display: block;
        font-size: 26px;
        color:  $red;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%); } }
