* {
	box-sizing: border-box; }
html {
	height: 100%;
	width: 100%;
	font-size: 16px; }
body {
	height: 100%;
	height: 100%;
	line-height: 1.42;
	-webkit-font-smoothing:antialiased {}
	margin: 0;
	display: flex;
	flex-direction: column; }
section {
	position: relative;
	width: 100%;
	overflow: hidden; }
ul, ol {
	margin: 0;
	padding: 0; }
li {
	list-style: none; }
a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
		outline: none; } }
button {
	cursor: pointer; }

button, input, a, textarea {
	&:focus {
		outline: 0;
		text-decoration: none; } }
p {
	margin: 0; }
h1, h2, h3, h4, h5 {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: normal; }
img {
	height: auto;
	max-width: 100%;
	display: block; }
figure {
	margin: 0; }
.wrapper {
	flex: 1 0 auto;
	overflow: hidden;
	@include font-face(sans); }
.footer {
	flex: 0 0 auto; }
.section__content {
	@include wrapper(); }
.section__head {
	text-align: center;
	margin-bottom: 40px; }
.section__line {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0; }
.section__polygon {
	fill: white; }
.section__title {
	color: black;
	@include font-face(bold);
	font-size: 36px;
	@include md-block {
		font-size: 32px; }
	@include sm-block {
		font-size: 28px;
		text-align: center; }
	@include xs-block {
		font-size: 24px; } }
.site__btn {
	border-radius: 20px;
	font-size: 14px;
	background-color: $red;
	color: white;
	border: 1px solid transparent;
	padding: 10px 15px;
	max-width: 155px;
	width: 100%;
	transition: .3s linear;
	@include xs-block {
		padding: 10px; }
	&:hover {
		background-color: darken($red,10%);
		box-shadow: 0 0 3px 1px rgba(0,0,0,.3); } }
