@font-face {
	font-family: 'openSans';
	src: url('/assets/fonts/OpenSans-Regular.woff') format('woff'), url('/assets/fonts/OpenSans-Regular.ttf') format('ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'exoBold';
	src: url('/assets/fonts/Exo2-Bold.woff') format('woff'), url('/assets/fonts/Exo2-Bold.ttf') format('ttf');
	font-weight: normal;
	font-style: normal; }
