.production {
	padding-top: 100px;
	padding-bottom: 70px;
	@include sm-block {
		padding-top: 60px; }
	@include xxs-block {
		padding-bottom: 30px; } }
.production__title {
	text-align: left;
	position: relative;
	display: flex;
	align-items: center;
	span {
		margin-right: 20px;
		white-space: nowrap; }
	&:after {
		content: '';
		height: 2px;
		width: 100%;
		max-width: 300px;
		background-color: #c7c7c7;
		display: inline-block; }
	@include mds-block {
		span {
			white-space: initial; } }
	@include sm-block {
		text-align: left; } }
.production__body {
	display: flex;
	@include sm-block {
		flex-direction: column; } }
.production__left, .production__right {
	flex: 1; }
.production__left {
	background-color: #e9e9e9;
	padding: 30px;
	@include sm-block {
		order: 2; } }
.production__right {
	@include sm-block {
		order: 1; } }
.production__text {
	p {
		margin-bottom: 30px;
		transition: .3s linear; }
	@include sm-block {
		p {
			margin-bottom: 15px; } } }
.production__img {
	position: relative;
	height: 100%;
	&:before {
		content: '';
		display: block;
		padding-top: 50%; } }
.production__img-pic {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0; }
