@keyframes afterWid {
	0% {
		width: 0; }
	100% {
		width: 100%; } }
@keyframes pulseTwo {
  50% {
   transform: scale(1.1);
   opacity: 1; }
  100% {
   transform: scale(2.2) {
    opacity: 0; } } }
