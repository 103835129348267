.about {
	padding-top: 100px;
	padding-bottom: 100px;
	transition: .3s linear;
	@include sm-block {
		padding-top: 80px;
		padding-bottom: 50px; }
	@include xxs-block {
		padding-top: 50px; } }
.about__list {
	@include row-flex();
	margin-bottom: 20px;
	justify-content: space-between;
	@include xxs-block {
		justify-content: center; } }
.about__item {
	@include size(3);
	@include size-xxs(6);
	@include size-xs(8);
	@include size-xss(10);
	padding: 10px;
	text-align: center;
	@include xxs-block {
		padding: 5px;
		margin-bottom: 10px; } }
.about__icon {
	max-width: 68px;
	min-height: 68px;
	width: 100%;
	display: inline-block;
	margin-bottom: 10px;
	background: url('/assets/images/ico/svg/ico-01.svg') center center no-repeat;
	transition: .3s linear;
	@include md-block {
		max-width: 50px;
		min-height: 50px; } }
.about__icon-two {
	background-image: url('/assets/images/ico/svg/ico-02.svg'); }
.about__icon-three {
	background-image: url('/assets/images/ico/svg/ico-03.svg'); }
.about__icon-four {
	background-image: url('/assets/images/ico/svg/ico-04.svg'); }
.about__text {
	font-weight: bold;
	@include xss-block {
		font-size: 14px; } }
.about__num {
	display: inline-block;
	color: $blue;
	@include font-face(bold);
	font-size: 50px;
	transition: .3s linear;
	@include md-block {
		font-size: 40px; } }
