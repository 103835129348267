.questions {
	background: url('/assets/images/questions/est-voprosi.jpg') center center no-repeat;
	background-size: cover;
	padding-top: 130px;
	padding-bottom: 170px;
	position: relative;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.4); }
	@include md-block {
		padding-bottom: 130px; }
	@include xxs-block {
		padding-top: 80px;
		padding-bottom: 80px; } }
.questions__line_top {
	top: 0; }
.questions__content {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative; }
.questions__wrap {
	max-width: 500px;
	padding: 25px 35px;
	display: inline-block;
	background-color: white; }
.questions__head {
	margin-bottom: 20px; }
.questions__title {
	margin-bottom: 10px;
	text-transform: uppercase; }
.questions__title-mini {
	@include font-face(bold);
	font-size: 18px; }
.questions__body {
	margin-bottom: 20px; }
.questions__elem {
	margin-bottom: 20px; }
.questions__inp {
	display: inline-block;
	width: 47%;
	margin-right: 2%;
	@include xxs-block {
		width: 60%;
		margin-bottom: 15px; }
	@include xs-block {
		width: 80%; } }
.questions__controls {
	text-align: right; }
