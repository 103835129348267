@-webkit-keyframes afterWid {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes afterWid {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes pulseTwo {
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    transform-opacity: 0;
  }
}

@keyframes pulseTwo {
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    transform-opacity: 0;
  }
}

@font-face {
  font-family: 'openSans';
  src: url("/assets/fonts/OpenSans-Regular.woff") format("woff"), url("/assets/fonts/OpenSans-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'exoBold';
  src: url("/assets/fonts/Exo2-Bold.woff") format("woff"), url("/assets/fonts/Exo2-Bold.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  font-size: 16px;
}

body {
  height: 100%;
  height: 100%;
  line-height: 1.42;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

section {
  position: relative;
  width: 100%;
  overflow: hidden;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

button {
  cursor: pointer;
}

button:focus,
input:focus,
a:focus,
textarea:focus {
  outline: 0;
  text-decoration: none;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

img {
  height: auto;
  max-width: 100%;
  display: block;
}

figure {
  margin: 0;
}

.wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  overflow: hidden;
  font-family: 'openSans';
}

.footer {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.section__content {
  max-width: 1170px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.section__head {
  text-align: center;
  margin-bottom: 40px;
}

.section__line {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.section__polygon {
  fill: white;
}

.section__title {
  color: black;
  font-family: 'exoBold';
  font-size: 36px;
}

.site__btn {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  font-size: 14px;
  background-color: #ef6966;
  color: white;
  border: 1px solid transparent;
  padding: 10px 15px;
  max-width: 155px;
  width: 100%;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.site__btn:hover {
  background-color: #ea3c38;
  -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
}

.header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 11;
  background-color: #2e2e2e;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.header.menu__scroll {
  position: fixed;
  -webkit-box-shadow: 0 0 5px 1px;
  box-shadow: 0 0 5px 1px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.header__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header__logo {
  -webkit-flex-basis: 15%;
  -ms-flex-preferred-size: 15%;
  flex-basis: 15%;
}

.header__link {
  font-size: 0;
  display: block;
}

.header__img {
  max-width: 150px;
  max-height: 60px;
}

.header__img-pic {
  width: 100%;
  height: auto;
}

.menu__wrapper {
  -webkit-flex-basis: 85%;
  -ms-flex-preferred-size: 85%;
  flex-basis: 85%;
}

.menu__vis {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav {
  -webkit-flex-basis: 55%;
  -ms-flex-preferred-size: 55%;
  flex-basis: 55%;
  position: relative;
}

.nav__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav__item {
  margin: 0 10px;
}

.nav__link {
  color: white;
  font-size: 14px;
  padding-bottom: 5px;
  text-transform: uppercase;
  position: relative;
}

.nav__link:after {
  content: '';
  display: block;
  height: 2px;
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
}

.nav__link:hover:after {
  -webkit-animation: afterWid .25s linear both;
  animation: afterWid .25s linear both;
}

.header__contacts {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-basis: 45%;
  -ms-flex-preferred-size: 45%;
  flex-basis: 45%;
}

.header__phone {
  margin: 0 10px;
  margin-bottom: 5px;
}

.header__phone-link {
  color: white;
  font-size: 18px;
  -webkit-transition: .3s lianer;
  -o-transition: .3s lianer;
  transition: .3s lianer;
}

.header__controls {
  text-align: center;
}

.header__menu {
  width: 25px;
  height: 25px;
  position: relative;
  display: none;
  cursor: pointer;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.header__menu:hover .header__menu-btn:before {
  top: 4px;
}

.header__menu:hover .header__menu-btn:after {
  bottom: 4px;
}

.header__menu.active .header__menu-btn {
  background-color: transparent;
}

.header__menu.active .header__menu-btn:before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header__menu.active .header__menu-btn:after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.header__menu-btn {
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.header__menu-btn:before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  top: 7px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.header__menu-btn:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  bottom: 7px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.hero {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.hero__fill-beige {
  fill: #fbf4ee;
}

.hero__bg {
  padding-top: 150px;
  background: url("/assets/images/hero/bg.jpg") bottom center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
}

.hero__bg:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.hero__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.hero__img-pic {
  max-width: 295px;
}

.hero__desc {
  margin-left: 50px;
}

.hero__title {
  color: white;
  font-family: 'exoBold';
  font-size: 50px;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.hero__advantage {
  padding-left: 30px;
}

.hero__advantage-elem {
  margin: 20px 0;
}

.hero__advantage-elem svg {
  vertical-align: middle;
}

.hero__advantage-elem span {
  font-size: 16px;
  color: white;
  margin-left: 15px;
  vertical-align: middle;
}

.hero__mark-line,
.hero__mark-circle {
  fill: transparent;
  stroke: #618BBE;
  stroke-width: 50px;
}

.hero__form {
  position: relative;
}

.hero__form .hero__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.catalogue {
  background-color: #f5f5f5;
  position: relative;
  padding-top: 130px;
  padding-bottom: 100px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.catalogue__line {
  top: 0;
}

.catalogue__fill-white {
  fill: white;
}

.catalogue__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.catalogue__item {
  width: -webkit-calc(25% - 15px);
  width: calc(25% - 15px);
  margin: 10px 7.5px;
}

.catalogue__link {
  display: block;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.catalogue__link:hover {
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
}

.catalogue__img {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 15px;
}

.catalogue__desc {
  font-size: 16px;
  color: black;
  padding: 10px;
}

.catalogue__name {
  font-weight: bold;
  margin-bottom: 10px;
  min-height: 40px;
  min-height: 60px;
}

.catalogue__box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.catalogue__price {
  color: #ef6966;
}

.catalogue__price,
.catalogue__controls {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.catalogue__btn {
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color: #618BBE;
}

.catalogue__btn:hover {
  background-color: #4f7eb7;
}

.catalogue__popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: black;
  z-index: 12;
  display: none;
  overflow: auto;
}

.popup__box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.popup__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 750px;
  max-height: 550px;
  background-color: white;
  padding: 20px;
  position: relative;
}

.popup__close {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.popup__close-btn {
  width: 100%;
  height: 2px;
  background-color: black;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

.popup__close-btn:before {
  content: '';
  display: block;
  position: absolute;
  background-color: black;
  width: 100%;
  height: 2px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.popup__left,
.popup__right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.popup__gallery {
  text-align: center;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.gallery__display {
  border: 1px solid #e9e9e9;
  max-width: 255px;
  display: inline-block;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.gallery__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 0 5px;
}

.gallery__item {
  cursor: pointer;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
  border: 1px solid transparent;
}

.gallery__item.active {
  border: 1px solid #618BBE;
}

.gallery__img {
  max-width: 50px;
}

.gallery__img-pic {
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.gallery__img-pic[data-src] {
  opacity: 0;
}

.popup__right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.popup__head {
  margin-bottom: 20px;
}

.popup__title {
  font-family: 'exoBold';
}

.popup__body {
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
  margin-bottom: 10px;
}

.popup__elem {
  margin-bottom: 10px;
}

.popup__btn {
  -webkit-border-radius: 0;
  border-radius: 0;
  max-width: initial;
  -webkit-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
}

.popup__controls {
  margin-top: auto;
}

.about {
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.about__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -7.5px;
  margin-right: -7.5px;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.about__item {
  width: -webkit-calc(25% - 15px);
  width: calc(25% - 15px);
  padding: 10px;
  text-align: center;
}

.about__icon {
  max-width: 68px;
  min-height: 68px;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  background: url("/assets/images/ico/svg/ico-01.svg") center center no-repeat;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.about__icon-two {
  background-image: url("/assets/images/ico/svg/ico-02.svg");
}

.about__icon-three {
  background-image: url("/assets/images/ico/svg/ico-03.svg");
}

.about__icon-four {
  background-image: url("/assets/images/ico/svg/ico-04.svg");
}

.about__text {
  font-weight: bold;
}

.about__num {
  display: inline-block;
  color: #618BBE;
  font-family: 'exoBold';
  font-size: 50px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.team {
  background: url("/assets/images/team/nasha-komanda.jpg") center center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 170px;
  position: relative;
}

.team:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.team__content {
  position: relative;
}

.team__title {
  color: white;
}

.team__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.team__item {
  width: -webkit-calc(33.33333% - 15px);
  width: calc(33.33333% - 15px);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 7.5px;
  margin-right: 7.5px;
  position: relative;
  padding-top: 90px;
  padding-bottom: 30px;
  padding-left: 20px;
  margin-top: 90px;
  display: inline-block;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: white;
  padding-right: 20px;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.team__img {
  max-width: 150px;
  max-height: 150px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -90px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 1px solid rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);
}

.team__name {
  font-family: 'exoBold';
  font-size: 18px;
  margin-bottom: 10px;
}

.team__job {
  color: rgba(0, 0, 0, 0.4);
}

.production {
  padding-top: 100px;
  padding-bottom: 70px;
}

.production__title {
  text-align: left;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.production__title span {
  margin-right: 20px;
  white-space: nowrap;
}

.production__title:after {
  content: '';
  height: 2px;
  width: 100%;
  max-width: 300px;
  background-color: #c7c7c7;
  display: inline-block;
}

.production__body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.production__left,
.production__right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.production__left {
  background-color: #e9e9e9;
  padding: 30px;
}

.production__text p {
  margin-bottom: 30px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.production__img {
  position: relative;
  height: 100%;
}

.production__img:before {
  content: '';
  display: block;
  padding-top: 50%;
}

.production__img-pic {
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.reviews {
  padding-top: 100px;
  padding-bottom: 70px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.reviews__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 20px;
  border: 5px solid #e9e9e9;
}

.reviews__img,
.reviews__desc {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 7.5px;
  margin-right: 7.5px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.reviews__img {
  text-align: center;
}

.reviews__desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.reviews__text {
  margin-bottom: 20px;
}

.reviews__user-name {
  font-size: 18px;
  font-family: 'exoBold';
}

.services-slider_prev {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.services-slider_prev::before {
  content: "\f053";
  font-family: 'FontAwesome';
  display: block;
  font-size: 26px;
  color: #ef6966;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.services-slider_next {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.services-slider_next::before {
  content: "\f054";
  font-family: 'FontAwesome';
  display: block;
  font-size: 26px;
  color: #ef6966;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.questions {
  background: url("/assets/images/questions/est-voprosi.jpg") center center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  padding-top: 130px;
  padding-bottom: 170px;
  position: relative;
}

.questions:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.questions__line_top {
  top: 0;
}

.questions__content {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
}

.questions__wrap {
  max-width: 500px;
  padding: 25px 35px;
  display: inline-block;
  background-color: white;
}

.questions__head {
  margin-bottom: 20px;
}

.questions__title {
  margin-bottom: 10px;
  text-transform: uppercase;
}

.questions__title-mini {
  font-family: 'exoBold';
  font-size: 18px;
}

.questions__body {
  margin-bottom: 20px;
}

.questions__elem {
  margin-bottom: 20px;
}

.questions__inp {
  display: inline-block;
  width: 47%;
  margin-right: 2%;
}

.questions__controls {
  text-align: right;
}

.section__map {
  padding-top: 100px;
  padding-bottom: 70px;
  min-height: 600px;
  max-height: 650px;
}

.map__wrap {
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
}

.map__title-mini {
  margin-bottom: 10px;
}

.map__text {
  color: #bdbdbd;
}

.map__controls {
  margin-top: 20px;
}

.map__phone-link {
  display: inline-block;
  color: black;
  font-size: 18px;
  font-weight: bold;
  width: 49%;
}

.footer {
  background-color: #2e2e2e;
  font-family: 'openSans';
}

.footer__text {
  color: rgba(255, 255, 255, 0.5);
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-basis: 35%;
  -ms-flex-preferred-size: 35%;
  flex-basis: 35%;
}

.form__head {
  margin-bottom: 20px;
}

.form__title {
  color: #ef6966;
  font-family: 'exoBold';
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.form__elem {
  margin: 0 10px;
}

.form__inp {
  border: 1px solid #ccc;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 15px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.form__inp:placeholde {
  color: #ccc;
}

.form__inp:focus {
  border-color: #ef6966;
}

.form__controls {
  text-align: center;
}

.form__hero-btn {
  min-width: 135px;
  font-size: 15px;
}

.form__filling {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 13;
  display: none;
}

.form__content {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.form__wrap {
  background-color: white;
  text-align: center;
  padding: 50px;
  max-width: 450px;
  width: 100%;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.4);
  position: relative;
}

.form__close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: black;
  width: 25px;
  height: 25px;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
}

.form__close-btn {
  width: 100%;
  height: 2px;
  background-color: black;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form__close-btn::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: black;
  position: absolute;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.form__filling-elem {
  margin: 0;
  margin-bottom: 15px;
}

.form__filling-inp {
  width: 100%;
  max-width: 250px;
}

.form__desc {
  margin: 10px 0;
  margin-bottom: 20px;
}

.form__btn {
  font-size: 15px;
  max-width: 250px;
}

.call__help {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #ef6966;
  text-align: center;
  cursor: pointer;
  display: none;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 3;
}

.call__help::before {
  content: '\f095';
  font-family: 'FontAwesome';
  color: #000;
  margin-right: 10px;
  display: inline-block;
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.call__inner {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #ef6966;
  -webkit-animation: pulseTwo 1s infinite;
  animation: pulseTwo 1s infinite;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

@media screen and (max-width: 1024px) {
  .section__title {
    font-size: 32px;
  }

  .header__phone-link {
    font-size: 16px;
  }

  .hero__img-pic {
    max-width: 170px;
  }

  .hero__title {
    font-size: 42px;
  }

  .hero__advantage-elem {
    margin: 10px 0;
  }

  .catalogue__desc {
    padding: 5px;
  }

  .popup__wrap {
    max-width: 700px;
    max-height: 500px;
  }

  .popup__body {
    font-size: 14px;
  }

  .about__icon {
    max-width: 50px;
    min-height: 50px;
  }

  .about__num {
    font-size: 40px;
  }

  .team {
    padding-bottom: 130px;
  }

  .team__item {
    padding-top: 70px;
  }

  .questions {
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 892px) {
  .nav {
    -webkit-flex-basis: 70%;
    -ms-flex-preferred-size: 70%;
    flex-basis: 70%;
  }

  .header__contacts {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-basis: 30%;
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
  }

  .team__list {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .team__item {
    padding-top: 50px;
  }

  .production__title span {
    white-space: initial;
  }
}

@media screen and (max-width: 768px) {
  .section__title {
    font-size: 28px;
    text-align: center;
  }

  .menu__wrapper {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.85);
  }

  .menu__vis {
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .nav {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .nav__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .nav__item {
    margin-bottom: 20px;
  }

  .header__contacts {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .header__phone {
    margin-bottom: 20px;
  }

  .header__menu {
    display: block;
  }

  .hero__bg {
    padding-top: 100px;
  }

  .hero__desc {
    padding-bottom: 50px;
    margin-left: 0;
  }

  .hero__title {
    font-size: 38px;
    text-align: center;
  }

  .hero__form {
    margin: auto 0;
  }

  .catalogue {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .catalogue__item {
    width: -webkit-calc(33.33333% - 15px);
    width: calc(33.33333% - 15px);
  }

  .about {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .team {
    padding-bottom: 100px;
  }

  .team__item {
    width: -webkit-calc(66.66667% - 15px);
    width: calc(66.66667% - 15px);
  }

  .team__item {
    padding-top: 90px;
    margin-bottom: 20px;
  }

  .production {
    padding-top: 60px;
  }

  .production__title {
    text-align: left;
  }

  .production__body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .production__left {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .production__right {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .production__text p {
    margin-bottom: 15px;
  }

  .reviews {
    padding-top: 80px;
  }

  .reviews__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .reviews__img {
    margin-bottom: 20px;
  }

  .reviews__img-pic {
    width: 100%;
  }

  .section__map {
    padding-top: 70px;
  }

  .footer__nav {
    display: none;
  }

  .form__head {
    margin-bottom: 15px;
  }

  .form__close {
    width: 20px;
    height: 20px;
  }

  .call__help {
    display: inline-block;
  }
}

@media screen and (max-width: 640px) {
  .hero__img-pic {
    display: none;
  }

  .hero__desc {
    padding-bottom: 30px;
  }

  .hero__title {
    font-size: 32px;
  }

  .hero__advantage-elem span {
    font-size: 14px;
  }

  .catalogue {
    padding-bottom: 30px;
  }

  .catalogue__item {
    width: -webkit-calc(50% - 15px);
    width: calc(50% - 15px);
  }

  .catalogue__desc {
    font-size: 14px;
  }

  .popup__box {
    height: auto;
  }

  .popup__wrap {
    max-height: initial;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
  }

  .popup__left {
    margin-bottom: 15px;
  }

  .about {
    padding-top: 50px;
  }

  .about__list {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .about__item {
    width: -webkit-calc(50% - 15px);
    width: calc(50% - 15px);
  }

  .about__item {
    padding: 5px;
    margin-bottom: 10px;
  }

  .team {
    padding-bottom: 80px;
  }

  .team__item {
    width: -webkit-calc(83.33333% - 15px);
    width: calc(83.33333% - 15px);
  }

  .production {
    padding-bottom: 30px;
  }

  .reviews {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .reviews__text {
    font-size: 14px;
  }

  .reviews__user-name {
    font-size: 16px;
  }

  .questions {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .questions__inp {
    width: 60%;
    margin-bottom: 15px;
  }

  .map__phone-link {
    width: 100%;
    margin-bottom: 10px;
  }

  .form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .form__elem {
    margin: 10px 0;
  }
}

@media screen and (max-width: 480px) {
  .section__title {
    font-size: 24px;
  }

  .site__btn {
    padding: 10px;
  }

  .hero__title {
    font-size: 28px;
  }

  .catalogue__box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: initial;
    -webkit-align-items: initial;
    -ms-flex-align: initial;
    align-items: initial;
  }

  .catalogue__price {
    margin-bottom: 10px;
  }

  .catalogue__btn {
    padding: 10px 15px;
    max-width: initial;
  }

  .about__item {
    width: -webkit-calc(66.66667% - 15px);
    width: calc(66.66667% - 15px);
  }

  .team__item {
    width: -webkit-calc(100% - 15px);
    width: calc(100% - 15px);
  }

  .questions__inp {
    width: 80%;
  }
}

@media screen and (max-width: 380px) {
  .about__item {
    width: -webkit-calc(83.33333% - 15px);
    width: calc(83.33333% - 15px);
  }

  .about__text {
    font-size: 14px;
  }

  .team__name {
    font-size: 16px;
  }

  .form__wrap {
    padding: 25px;
  }
}

@media screen and (max-height: 500px) {
  .popup__box {
    height: auto;
  }
}