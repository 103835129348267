.header {
	position: absolute;
	left: 0;
	right: 0;
	z-index: 11;
	background-color: #2e2e2e;
	transition: .3s linear;
	&.menu__scroll {
		position: fixed;
		box-shadow: 0 0 5px 1px;
		transition: .3s linear; } }
.header__content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 10px; }
.header__logo {
	flex-basis: 15%; }
.header__link {
	font-size: 0;
	display: block; }
.header__img {
	max-width: 150px;
	max-height: 60px; }
.header__img-pic {
	width: 100%;
	height: auto; }
.menu__wrapper {
	flex-basis: 85%;
	@include sm-block {
		display: none;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background-color: rgba(0,0,0,.85); } }
.menu__vis {
	display: flex;
	align-items: center;
	@include sm-block {
		height: 100%;
		flex-direction: column;
		justify-content: center; } }
.nav {
	flex-basis: 55%;
	position: relative;
	@include mds-block {
		flex-basis: 70%; }
	@include sm-block {
		flex-basis: auto; } }
.nav__list {
	display: flex;
	align-items: center;
	@include sm-block {
		flex-direction: column; } }

.nav__item {
	margin: 0 10px;
	@include sm-block {
		margin-bottom: 20px; } }
.nav__link {
	color: white;
	font-size: 14px;
	padding-bottom: 5px;
	text-transform: uppercase;
	position: relative;
	&:after {
		content: '';
		display: block;
		height: 2px;
		background: white;
		position: absolute;
		bottom: 0;
		left: 0; }
	&:hover {
		&:after {
			animation: afterWid .25s linear both; } } }
.header__contacts {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: flex-end;
	flex-basis: 45%;
	@include mds-block {
		flex-direction: column;
		flex-basis: 30%; }
	@include sm-block {
		flex-basis: auto; } }
.header__phone {
	margin: 0 10px;
	margin-bottom: 5px;
	@include sm-block {
		margin-bottom: 20px; } }
.header__phone-link {
	color: white;
	font-size: 18px;
	transition: .3s lianer;
	@include md-block {
		font-size: 16px; } }
.header__controls {
	text-align: center; }
.header__menu {
	width: 25px;
	height: 25px;
	position: relative;
	display: none;
	cursor: pointer;
	transition: .3s linear;
	&:hover {
		.header__menu-btn {
			&:before {
				top: 4px; }
			&:after {
				bottom: 4px; } } }
	@include sm-block {
		display: block; }
	&.active {
		.header__menu-btn {
			background-color: transparent;
			&:before {
				top: 0;
				transform: rotate(45deg); }
			&:after {
				bottom: 0;
				transform: rotate(-45deg); } } } }
.header__menu-btn {
	width: 100%;
	height: 2px;
	background-color: white;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: .3s linear;
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		background-color: white;
		position: absolute;
		top: 7px;
		transition: .3s linear; }
	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		background-color: white;
		position: absolute;
		bottom: 7px;
		transition: .3s linear; } }

