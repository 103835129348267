.section__map {
	padding-top: 100px;
	padding-bottom: 70px;
	min-height: 600px;
	max-height: 650px;
	@include sm-block {
		padding-top: 70px; } }
.map__wrap {
	box-shadow: 0 0 10px 1px rgba(0,0,0,.15); }
.map__title-mini {
	margin-bottom: 10px; }
.map__text {
	color: #bdbdbd; }
.map__controls {
	margin-top: 20px; }
.map__phone-link {
	display: inline-block;
	color: black;
	font-size: 18px;
	font-weight: bold;
	width: 49%;
	@include xxs-block {
		width: 100%;
		margin-bottom: 10px; } }
