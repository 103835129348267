.catalogue {
	background-color: #f5f5f5;
	position: relative;
	padding-top: 130px;
	padding-bottom: 100px;
	transition: .3s linear;
	@include sm-block {
		padding-top: 80px;
		padding-bottom: 50px; }
	@include xxs-block {
		padding-bottom: 30px; } }
.catalogue__line {
	top: 0; }
.catalogue__fill-white {
	fill: white; }
.catalogue__list {
	@include row-flex(); }
.catalogue__item {
	@include size(3);
	@include size-sm(4);
	@include size-xxs(6);
	margin: 10px 7.5px; }
.catalogue__link {
	display: block;
	transition: .3s linear;
	&:hover {
		box-shadow: 0 0 10px 1px rgba(0,0,0,.15); } }

.catalogue__img {
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 15px; }

.catalogue__desc {
	font-size: 16px;
	color: black;
	padding: 10px;
	@include md-block {
		padding: 5px; }
	@include xxs-block {
		font-size: 14px; } }
.catalogue__name {
	font-weight: bold;
	margin-bottom: 10px;
	min-height: 40px;
	@include xxs-block;
	min-height: 60px; }
.catalogue__box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include xs-block {
		flex-direction: column;
		align-items: initial; } }
.catalogue__price {
	color: $red;
	@include xs-block {
		margin-bottom: 10px; } }
.catalogue__price, .catalogue__controls {
	flex: 1; }
.catalogue__btn {
	border-radius: 0;
	background-color: $blue;
	@include xs-block {
		padding: 10px 15px;
		max-width: initial; }
	&:hover {
		background-color: darken($blue,5%); } }
.catalogue__popup {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: black;
	z-index: 12;
	display: none;
	overflow: auto; }
.popup__box {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	@include sm-height {
		height: auto; }
	@include xxs-block {
		height: auto; } }
.popup__wrap {
	display: flex;
	max-width: 750px;
	max-height: 550px;
	background-color: white;
	padding: 20px;
	position: relative;
	@include md-block {
		max-width: 700px;
		max-height: 500px; }
	@include xxs-block {
		max-height: initial;
		flex-direction: column;
		height: auto; } }

.popup__close {
	position: absolute;
	width: 20px;
	height:	20px;
	right: 5px;
	top: 5px;
	cursor: pointer; }
.popup__close-btn {
	width: 100%;
	height: 2px;
	background-color: black;
	position: absolute;
	top: 50%;
	transform: translateY(-50%) rotate(45deg);
	&:before {
		content: '';
		display: block;
		position: absolute;
		background-color: black;
		width: 100%;
		height: 2px;
		transform: rotate(90deg); } }
.popup__left, .popup__right {
	flex: 1; }
.popup__left {
	@include xxs-block {
		margin-bottom: 15px; } }
.popup__gallery {
	text-align: center;
	transition: .3s linear; }
.gallery__display {
	border: 1px solid #e9e9e9;
	max-width: 255px;
	display: inline-block;
	transition: .3s linear; }
.gallery__list {
	display: flex;
	width: 100%;
	overflow: hidden;
	padding: 0 5px; }
.gallery__item {
	cursor: pointer;
	transition: .3s linear;
	border: 1px solid transparent;
	&.active {
		border: 1px solid $blue; } }
.gallery__img {
	max-width: 50px; }
.gallery__img-pic {
	opacity: 1;
	transition: opacity 0.3s; }
.gallery__img-pic[data-src] {
	opacity: 0; }
.popup__right {
	display: flex;
	flex-direction: column; }
.popup__head {
	margin-bottom: 20px; }
.popup__title {
	@include font-face(bold); }
.popup__body {
	transition: .3s linear;
	margin-bottom: 10px;
	@include md-block {
		font-size: 14px; } }
.popup__elem {
	margin-bottom: 10px; }
.popup__btn {
	border-radius: 0;
	max-width: initial;
	box-shadow: 0 2px 3px 1px rgba(0,0,0,.3);
	text-transform: uppercase; }
.popup__controls {
	margin-top: auto; }
