.footer {
	background-color: #2e2e2e;
	@include font-face(sans); }
.footer__text {
	color: rgba(255,255,255,.5);
	justify-content: flex-end;
	flex-basis: 35%; }
.footer__nav {
	@include sm-block {
		display: none; } }
