.team {
	background: url('/assets/images/team/nasha-komanda.jpg') center center no-repeat;
	background-size: cover;
	padding-top: 80px;
	padding-bottom: 170px;
	position: relative;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.4); }
	@include md-block {
		padding-bottom: 130px; }
	@include sm-block {
		padding-bottom: 100px; }
	@include xxs-block {
		padding-bottom: 80px; } }
.team__content {
	position: relative; }
.team__title {
	color: white; }
.team__list {
	@include row-flex();
	@include mds-block {
		justify-content: center; } }
.team__item {
	@include size(4);
	@include size-sm(8);
	@include size-xxs(10);
	@include size-xs(12);
	@include col();
	position: relative;
	padding-top: 90px;
	padding-bottom: 30px;
	padding-left: 20px;
	margin-top: 90px;
	display: inline-block;
	border-radius: 5px;
	background-color:	white;
	padding-right: 20px;
	box-shadow: 0 0 5px 1px rgba(0,0,0,.2);
	transition: .3s linear;
	@include md-block {
		padding-top: 70px; }
	@include mds-block {
		padding-top: 50px; }
	@include sm-block {
		padding-top: 90px;
		margin-bottom: 20px; } }
.team__img {
	max-width: 150px;
	max-height: 150px;
	border-radius: 50%;
	overflow: hidden;
	position: absolute;
	top: -90px;
	left: 50%;
	transform: translateX(-50%);
	border: 1px solid rgba(0,0,0,.35);
	box-shadow: 0 0 10px 1px rgba(0,0,0,.35); }
.team__name {
	@include font-face(bold);
	font-size: 18px;
	margin-bottom: 10px;
	@include xss-block {
		font-size: 16px; } }
.team__job {
	color: rgba(0,0,0,.4); }
