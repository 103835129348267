.form__head {
	margin-bottom: 20px;
	@include sm-block {
		margin-bottom: 15px; } }
.form__title {
	color: $red;
	@include font-face(bold); }
.form {
	display: flex;
	@include xxs-block {
		flex-direction: column; } }
.form__elem {
	margin: 0 10px;
	@include xxs-block {
		margin:	10px 0; } }
.form__inp {
	border:	1px solid #ccc;
	border-radius: 20px;
	padding: 10px 15px;
	font-size: 15px;
	transition: .3s linear;
	&:placeholde {
		color: #ccc; }
	&:focus {
		border-color: $red; } }
.form__controls {
	text-align: center; }
.form__hero-btn {
	min-width: 135px;
	font-size: 15px; }


// form__filling
.form__filling {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,.85);
	z-index: 13;
	display: none; }
.form__content {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center; }
.form__wrap {
	background-color: white;
	text-align: center;
	padding: 50px;
	max-width: 450px;
	width: 100%;
	border-radius: 5px;
	box-shadow: 0 0 3px 1px rgba(255,255,255,.4);
	position: relative;
	@include xss-block {
		padding: 25px; } }
.form__close {
	position: absolute;
	right: 10px;
	top: 10px;
	color: black;
	width: 25px;
	height: 25px;
	border: 1px solid transparent;
	background-color: transparent;
	cursor: pointer;
	@include sm-block {
		width: 20px;
		height: 20px; } }
.form__close-btn {
	width: 100%;
	height: 2px;
	background-color: black;
	position: absolute;
	top: 50%;
	transform: rotate(45deg);
	&::before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background-color: black;
		position: absolute;
		transform: rotate(90deg); } }
.form__filling-elem {
	margin: 0;
	margin-bottom: 15px; }
.form__filling-inp {
	width: 100%;
	max-width: 250px; }
.form__desc {
	margin: 10px 0;
	margin-bottom: 20px; }
.form__btn {
	font-size: 15px;
	max-width: 250px; }
.call__help {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: $red;
	text-align: center;
	cursor: pointer;
	display: none;
	position: fixed;
	bottom: 15px;
	right: 15px;
	z-index: 3;
	@include sm-block {
		display: inline-block; }
	&::before {
		content: '\f095';
		font-family: 'FontAwesome';
		color: #000;
		margin-right: 10px;
		display: inline-block;
		font-size: 22px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%); } }

.call__inner {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 1px solid $red;
	animation: pulseTwo 1s infinite;
	border-radius: 50%; }

