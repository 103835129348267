.hero {
	position: relative;
	display: flex;
	flex-direction: column; }
.hero__fill-beige {
	fill:  #fbf4ee; }
.hero__bg {
	padding-top: 150px;
	background: url('/assets/images/hero/bg.jpg') bottom center no-repeat;
	background-size: cover;
	position: relative;
	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.5); }
	@include sm-block {
		padding-top: 100px; } }
.hero__content {
	display: flex;
	align-items: center;
	position: relative; }
.hero__img-pic {
	max-width: 295px;
	@include md-block {
		max-width: 170px; }
	@include xxs-block {
		display: none; } }
.hero__desc {
	margin-left: 50px;
	@include sm-block {
		padding-bottom: 50px;
		margin-left: 0; }
	@include xxs-block {
		padding-bottom: 30px; } }
.hero__title {
	color: white;
	@include font-face(bold);
	font-size: 50px;
	text-shadow: 1px 2px 2px rgba(0,0,0,.5);
	transition: .3s linear;
	@include md-block {
		font-size: 42px; }
	@include sm-block {
		font-size: 38px;
		text-align: center; }
	@include xxs-block {
		font-size: 32px; }
	@include xs-block {
		font-size: 28px; } }
.hero__advantage {
	padding-left: 30px; }
.hero__advantage-elem {
	margin: 20px 0;
	@include md-block {
		margin: 10px 0; }
	@include sm-block;
	svg {
		vertical-align: middle; }
	span {
		font-size: 16px;
		color: white;
		margin-left: 15px;
		vertical-align: middle;
		@include xxs-block {
			font-size: 14px; } } }
.hero__mark-line, .hero__mark-circle {
	fill: transparent;
	stroke: $blue;
	stroke-width: 50px; }
.hero__form {
	position: relative;
	@include sm-block {
		margin: auto 0; }
	.hero__content {
		flex-direction: column; } }
